.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 1000px;

  background-color: #326460;
  margin-bottom: -150px;
}
.graphicChar__container {
  height: 600px;
  width: 1200px;
  background-color: #cce2e2;
  border-radius: 10px;
}
.link__container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -100px;
  padding-bottom: 50px;
  width: 200px;
  height: 50px;
}

.link__container div {
  cursor: pointer;
  font-size: large;
  color: #3e440b;
  font-weight: 600;
}

@media screen and (max-width: 1270px) {
  .graphicChar__container {
    height: 520px;
    width: 1000px;
  }
  .link__container {
    margin-top: -80px;
  }

  .container {
    height: 900px;

    margin-bottom: -150px;
  }
}

@media screen and (max-width: 1050px) {
  .graphicChar__container {
    height: 430px;
    width: 800px;
  }
  .link__container {
    margin-top: -70px;
  }
  .container {
    height: 800px;

    margin-bottom: -150px;
  }
}

@media screen and (max-width: 850px) {
  .graphicChar__container {
    height: 400px;
    width: 700px;
  }
  .link__container {
    margin-top: 0px;
  }
  .container {
    height: 1000px;
    margin-top: -120px;
    margin-bottom: -120px;
  }
}

@media screen and (max-width: 420px) {
  .graphicChar__container {
    height: 270px;
    width: 550px;
  }
  .link__container {
    margin-top: 50px;
  }
  .container {
    height: 420px;
    width: 650px;

    margin-bottom: 100px;
    margin-left: -120px;
    margin-top: 180px;
    transform: rotate(90deg);
  }
}

@media screen and (max-height: 420px) {
  .graphicChar__container {
    height: 370px;
    width: 650px;
  }
  .link__container {
    margin-top: 40px;
    padding-bottom: 10px;
  }
  .container {
    height: 520px;
    width: auto;

    margin-bottom: 0px;
    margin-left: 0px;
    margin-top: 0px;
  }
}
