.container {
  padding: 10px 10px;
  background: #15dd90;
  border-top: 1px solid #363c4e;
  transition: all 0.3s ease-in-out;
}

.footer {
  max-width: 1600px;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
}

.footer_logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 80px;
}

.footer_logo img {
  height: 200px;
  max-width: 100%;
  margin: auto;
}

.footer_text {
  margin: 10px;
  font-family: "Lato", sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: rgb(9, 38, 63);
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 66.66666%;
  max-width: 66.66666%;
}

@media screen and (max-width: 1200px) {
  .footer_logo img {
    height: 160px;
    margin: 50px;
  }

  .footer_text {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 810px) {
  .footer_logo img {
    height: 120px;

    margin: 30px;
  }
  .footer_text {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 415px) {
  .footer_logo img {
    height: 60px;

    margin: -10px;
  }
  .footer_text {
    font-size: 0.6rem;
    margin: 10px;
  }
  .footer_logo {
    margin-right: 10px;
  }
  .footer {
    padding-right: 0px;
  }
}
