.inputSearch__container {
  position: relative;
}
.inputSearch__input {
  appearance: none;
  background-color: rgb(255, 255, 255);
  border-color: rgb(216, 218, 229);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  color: rgb(71, 77, 102);
  font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  font-size: 12px;
  height: 32px;
  line-height: 16px;
  outline-color: rgb(71, 77, 102);
  outline-style: none;
  outline-width: 0px;
  padding-left: 12px;
  padding-right: 12px;
  width: 280px;
}

.autocomplete__container {
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: rgba(67, 90, 111, 0.3) 0px 0px 1px 0px,
    rgba(67, 90, 111, 0.47) 0px 8px 10px -4px;
  /*  box-sizing: border-box; */
  color: rgb(67, 90, 111);
  font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  height: 160px;
  width: 280px;
  opacity: 1;
  overflow: hidden;
  position: absolute;
  top: 32px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  transform-origin: 140px 0px;
  transition-duration: 0.5s;
  transition-property: opacity, transform;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.175);
  visibility: hidden;
  z-index: 10;
}

.autocomplete__container_active {
  visibility: visible;
}

.autocomplete__item_container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: rgb(67, 90, 111);
  padding: 8px;
}

.autocomplete__item_container:hover {
  cursor: pointer;
  background-color: rgb(249, 250, 252);
  border-bottom-color: rgb(237, 239, 245);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  text-decoration-color: rgb(67, 90, 111);
  text-decoration-line: underline;
  text-decoration-style: solid;
}

.autocomplete__item {
  color: rgb(71, 77, 102);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

@media screen and (max-width: 810px) {
  .inputSearch__input {
    height: 22px;
    line-height: 10px;
    font-size: 16px;
    padding-left: 12px;
    padding-right: 12px;
    width: 200px;
  }
  .autocomplete__container {
    top: 22px;
    width: 200px;
  }
}

@media screen and (max-width: 420px) {
  .inputSearch__input {
    height: 20px;
    line-height: 10px;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    width: 200px;
  }

  .inputSearch__container {
    margin-left: 20px;
  }
}
