.container {
  background-color: #326460;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container__text {
  width: auto;
  margin-right: 60px;
  margin-left: 60px;
  margin-top: 40px;
  color: aliceblue;
}

h1 {
  margin-bottom: 20px;
  text-align: center;
}

p {
  margin-bottom: 20px;
}

.container__error_button {
  width: 250px;
  height: 50px;
  background-color: blueviolet;
  border-radius: 15px;
  border: 1px solid #363c4e;
  margin: 30px 0;
  font-size: 16px;
  cursor: pointer;
}

@media screen and (max-width: 810px) {
  .container {
    margin-top: 80px;
  }
  .container__text {
    margin-right: 40px;
    margin-left: 40px;
    margin-top: 30px;
  }
  .container__error_button {
    width: 150px;
    height: 30px;
    margin: 15px 0;
    font-size: 12px;
  }
}

@media screen and (max-width: 420px) {
  .container {
    margin-top: 70px;
  }

  .container__text {
    margin-right: 30px;
    margin-left: 30px;
    margin-top: 20px;
  }
}

@media screen and (max-height: 420px) {
  .container {
    margin-top: 60px;
  }

  .container__text {
    margin-right: 30px;
    margin-left: 30px;
    margin-top: 20px;
  }
}
