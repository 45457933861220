.container {
  min-height: 100vh;
  background-color: #326460;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.img {
  width: 550px;
}
.text {
  margin-top: 30px;
  font-size: 30px;
  color: white;
  text-align: center;
  text-shadow: rgb(27, 22, 1) 5px 10px 10px;
}

.container__error_button {
  width: 350px;
  height: 100px;
  background-color: rgb(207, 76, 164);
  border-radius: 15px;
  border: 1px solid #363c4e;
  margin: 30px 0;
  font-size: 16px;
  cursor: pointer;
}

@media screen and (max-width: 570px) {
  .img {
    width: 320px;
  }
  .text {
    margin-top: 20px;
    font-size: 20px;
  }

  .container__error_button {
    width: 250px;
    height: 80px;

    margin: 20px 0;
    font-size: 14px;
  }
}
