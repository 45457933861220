.card {
  display: flex;
  width: 500px;
  flex-direction: column;
  border: 1px solid #363c4e;
  position: relative;
  z-index: 9;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  padding: 5px;
  border-radius: 15px;
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, 0.05);

  background-color: rgb(71, 176, 211);
  cursor: pointer;
}
.card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 10px 0px 10px;
  font-family: "Lato", sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: rgb(9, 38, 63);
}
.card__value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 10px 0px 10px;
  font-family: "Lato", sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: rgb(9, 38, 63);
}

.card__value h3 {
  padding-left: 2.2rem;
}

.card__graphic {
  width: 450px;
  height: 230px;
}

@media screen and (max-width: 1190px) {
  .card {
    width: 800px;
  }

  .card__graphic {
    width: 750px;
  }
}

@media screen and (max-width: 970px) {
  .card {
    width: 700px;
  }
  .card__graphic {
    width: 650px;
  }
}

@media screen and (max-width: 810px) {
  .card {
    width: 650px;
  }
  .card__graphic {
    width: 600px;
  }
}

@media screen and (max-width: 420px) {
  .card {
    width: var(--cart-width-ml);
  }
  .card__graphic {
    width: 330px;
  }
}

@media screen and (max-width: 390px) {
  .card {
    width: var(--cart-width-ms);
  }
  .card__graphic {
    width: 280px;
  }
}
