.container {
  height: 35px;
  width: 35px;
  border-radius: 6px;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: all 0.4s linear;
  overflow: hidden;
}

.active {
  background-color: #de0611;
}

.menu {
  position: relative;
  height: 100%;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.line {
  position: absolute;
  height: 4px;
  width: 100%;
  border-radius: 6px;
  background-color: #2c3e50;
  transition: all 0.4s linear;
}

.menu .line__one {
  top: 8px;
}

.active .line__one {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  background-color: #fff;
}

.line__three {
  bottom: 8px;
}
.line__two {
  left: 0;
  opacity: 1;
}

.active .line__two {
  left: -100%;
  opacity: 0;
  background-color: #de0611;
}

.active .line__three {
  bottom: 50%;
  transform: translateY(50%) rotate(-45deg);
  background-color: #fff;
}

.container .dropdown__menu {
  background-color: #fff;
  padding: 1rem 1.25rem;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  position: absolute;
  width: 230px;
  right: 1rem;
  top: 4.5rem;
  transform: scale(0.1);
  transform-origin: 10rem -2rem;
  pointer-events: none;
  transition: opacity 0.4s, transform 0.4s;
  opacity: 0;
}

.dropdown__item {
  cursor: pointer;
  transition: color 0.3s;
}

.dropdown__item:hover {
  color: #35a899;
}

.dropdown__name {
  font-weight: 400;
}
.active .dropdown__menu {
  opacity: 1;
  transform: scale(1);
  pointer-events: initial;
}
.dropdown__item {
  display: block;
}

@media screen and (max-width: 420px) {
  .container {
    height: 25px;
    width: 25px;
  }
  .menu {
    width: 20px;
  }
  .line {
    height: 2px;
    width: 20px;
  }
  .menu .line__one {
    top: 6px;
    height: 2px;
  }
  .line__three {
    bottom: 6px;
    height: 2px;
  }
  .active .line__one {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    background-color: #fff;
  }
  .active .line__three {
    bottom: 50%;
    transform: translateY(50%) rotate(-45deg);
    background-color: #fff;
  }
}
