.checkbox {
  width: 75px;
  height: 150px;
  border-radius: 25px;
  background: linear-gradient(0deg, #d8d8d8, #cccccc);
  border-top: 0.02em solid #ececec;
  border-bottom: 0.02em solid #ececec;
  transition: 0.5s;
}
.checkbox .inner {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background: linear-gradient(5deg, #12e7dd, #129e97);
  border-radius: 20px;
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.5);
}
.checkbox .inner .toggle__checkbox {
  position: absolute;
  top: -2px;
  left: 1px;
  width: 53px;
  height: 53px;
  border-radius: 50%;
  background: linear-gradient(0deg, #ccc, #e4e4e4);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border-top: 0.04em solid #ececec;
  border-bottom: 0.01em solid #ececec;
  transition: 0.5s;
}
.checkbox .inner .toggle__checkbox:before {
  content: "Rechart";
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  background: linear-gradient(0deg, #e4e4e4, #ccc);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 28px;
  color: #12e7dd;
}
.checkbox .inner.active .toggle__checkbox:before {
  content: "Nivo";
  color: #c25f0f;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkbox .inner.active .toggle__checkbox {
  top: 60%;
}
.checkbox .inner.active {
  background: linear-gradient(5deg, #c25f0f, #dd7520);
}
.toggle__checkbox {
  cursor: pointer;
}

@media screen and (max-width: 810px) {
  .checkbox {
    width: 45px;
    height: 120px;
  }
  .checkbox .inner .toggle__checkbox {
    top: 1px;
    left: 1px;
    width: 23px;
    height: 23px;
  }
  .checkbox .inner .toggle__checkbox:before {
    font-size: 0px;
  }
  .checkbox .inner.active .toggle__checkbox {
    top: 75%;
  }
}

@media screen and (max-width: 420px) {
  .checkbox {
    width: 25px;
    height: 60px;
    transform: rotate(-90deg);
    transform-origin: -10px -10px;
  }
  .checkbox .inner .toggle__checkbox {
    top: 1px;
    left: 1px;
    width: 13px;
    height: 13px;
  }

  .checkbox .inner.active .toggle__checkbox {
    top: 70%;
  }

  .checkbox .inner {
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
  }
}

@media screen and (max-width: 390px) {
  .checkbox {
    transform-origin: -6px -6px;
  }
}

@media screen and (max-height: 420px) {
  .checkbox {
    width: 25px;
    height: 60px;
    transform: rotate(-90deg);
    transform-origin: 30px 30px;
  }
  .checkbox .inner .toggle__checkbox {
    top: 1px;
    left: 1px;
    width: 13px;
    height: 13px;
  }

  .checkbox .inner.active .toggle__checkbox {
    top: 70%;
  }

  .checkbox .inner {
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
  }
}
