.container {
  margin-top: 100px;
  background-color: #326460;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
}

.img {
  width: 550px;
  margin: auto;
  margin-top: 100px;
}
.text {
  margin-top: 30px;
  font-size: 30px;
  color: white;
  text-align: center;
  text-shadow: rgb(27, 22, 1) 5px 10px 10px;
}

@media screen and (max-width: 810px) {
  .container {
    margin-top: 80px;
  }
}

@media screen and (max-width: 570px) {
  .container {
    margin-top: 60px;
  }
  .img {
    width: 350px;

    margin-top: 80px;
  }
  .text {
    margin-top: 20px;
    font-size: 20px;
  }
}

@media screen and (max-height: 420px) {
  .container {
    margin-top: 50px;
  }
  .img {
    width: 350px;

    margin-top: 60px;
  }
  .text {
    margin-top: 20px;
    font-size: 16px;
  }
}
