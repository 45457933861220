.container {
  padding: 10px 20px;
  background: #15dd90;
  border-bottom: 1px solid #363c4e;
  z-index: 10;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}

.navigation {
  max-width: 1400px;
  width: 100%;
  height: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: nowrap;
}
.navbar__brand {
  display: flex;
  justify-content: left;

  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 33.3333%;
  max-width: 33.33333%;
}

.navbar__brand img {
  display: flex;
  align-items: center;
  width: var(--header-height-large);

  max-width: var(--header-height-large);
  max-height: var(--header-height-large);
  background-color: transparent;
  margin: auto 0;
}
.navbar__brand img:hover {
  transform: rotate(360deg);
  transition: transform 3s ease;
}
.navbar__logo {
  display: flex;
  align-items: center;
  width: 100px;
  max-height: var(--header-height-large);
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 33.3333%;
  max-width: 33.33333%;
  justify-content: center;
}

.navbar__logo img {
  width: 200px;
  max-height: var(--header-height-large);
}
.main__nav {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 33.3333%;
  max-width: 33.33333%;
  justify-content: right;
  flex-wrap: nowrap;
  align-items: center;
}
.main__nav ul {
  display: flex;
  flex-direction: row;
  list-style: none;
  padding-right: 10px;
}

li {
  margin: 10px;
  font-family: "Lato", sans-serif;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.5;
  position: relative;
}

a {
  color: #1f5163;
  text-decoration: none;
}
a:hover::after {
  transform: scaleX(1);
}
a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 3px;
  transform: scaleX(0);
  background-color: rgb(71, 176, 211);

  transition: transform 0.3s;
}

@media screen and (max-width: 810px) {
  .navbar__brand img {
    width: var(--header-height-medium);

    max-width: var(--header-height-medium);
    max-height: var(--header-height-medium);
  }

  .navbar__logo {
    max-height: var(--header-height-medium);
  }

  .navbar__logo img {
    max-height: var(--header-height-medium);
    width: 150px;
  }
  li {
    margin: 8px;

    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;
  }
}

.navbar__dropdown {
  display: none;
}

@media screen and (max-width: 630px) {
  li {
    display: none;
  }
  .navbar__dropdown {
    display: block;
  }
}

@media screen and (max-width: 420px) {
  .navbar__brand img {
    width: var(--header-height-small);

    max-width: var(--header-height-small);
    max-height: var(--header-height-small);
  }

  .navbar__logo {
    max-height: var(--header-height-small);
  }

  .navbar__logo img {
    max-height: var(--header-height-small);
    width: 120px;
  }
}

@media screen and (max-height: 420px) {
  .navbar__brand img {
    width: var(--header-height-small);

    max-width: var(--header-height-small);
    max-height: var(--header-height-small);
  }

  .navbar__logo {
    max-height: var(--header-height-small);
  }

  .navbar__logo img {
    max-height: var(--header-height-small);
    width: 120px;
  }
  li {
    margin: 8px;

    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;
  }
}
